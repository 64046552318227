import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 25,
    title: "Creazione della Tua Prima App MERN",
    desc: "",
    img: "/blog-image/mern.png",
    page: "blog/mern",
    data: "06 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Lo stack MERN è una soluzione completa per lo sviluppo di applicazioni web moderne, comprendendo MongoDB, Express.js, React e Node.js.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3>Introduzione</h3>
                                    <p>
                                        Lo stack MERN è una soluzione completa
                                        per lo sviluppo di applicazioni web
                                        moderne, comprendendo MongoDB,
                                        Express.js, React e Node.js. Se stai
                                        cercando di costruire la tua prima app
                                        MERN, sei nel posto giusto! In questo
                                        articolo, ti guideremo attraverso i
                                        passi fondamentali per creare una
                                        semplice applicazione con lo stack MERN.{" "}
                                    </p>

                                    <h3>Fasi di Sviluppo:</h3>

                                    <ol>
                                        <li>
                                            <h5>
                                                Configurazione dell'Ambiente di
                                                Sviluppo:
                                            </h5>
                                            <p>
                                                Inizia installando Node.js e
                                                MongoDB sul tuo sistema, se non
                                                li hai già. Dopo, puoi creare
                                                una nuova app React:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npx create-react-app 
mia-prima-app-mern
cd mia-prima-app-mern`}
                                                language="javascript"
                                                showLineNumbers={false}
                                            />
                                        </li>
                                        <li>
                                            <h5>
                                                Installazione delle Dipendenze:
                                            </h5>
                                            <p>
                                                Ora, installa le dipendenze
                                                necessarie:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npm install express mongoose cors axios`}
                                                language="javascript"
                                                showLineNumbers={false}
                                            />
                                        </li>
                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li>
                                            <h5>Configurazione del Backend:</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        Crea una cartella server
                                                        nella radice del
                                                        progetto.
                                                    </p>
                                                </li>
                                                <li>
                                                    All'interno, crea un file
                                                    server.js e configura
                                                    Express e MongoDB
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h5>
                                                Sviluppo del Frontend con React:
                                            </h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        Torna alla cartella
                                                        principale del tuo
                                                        progetto.
                                                    </p>
                                                    <p>
                                                        Modifica il file
                                                        src/App.js per
                                                        comunicare con il tuo
                                                        server Express.
                                                    </p>
                                                    <MyCoolCodeBlock
                                                        code={`import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  // Qui implementerai la logica della tua app e i metodi per interagire con il server
    return (
        <div>
            {/* E qui renderizzerai la tua UI */}
        </div>
    );
}

export default App;
`}
                                                        language="javascript"
                                                        showLineNumbers={false}
                                                    />
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>

                                    <h3>Prossimi Passi:</h3>
                                    <p>
                                        Ora che hai impostato il tuo ambiente di
                                        sviluppo e hai creato il tuo frontend e
                                        backend di base, sei pronto per iniziare
                                        a sviluppare la tua app! Continua a
                                        definire i tuoi modelli di dati con
                                        Mongoose, a creare i tuoi componenti
                                        React, e a implementare le funzionalità
                                        della tua applicazione. Non dimenticare
                                        di testare la tua app man mano che
                                        procedi, per assicurarti che tutto
                                        funzioni come previsto.
                                    </p>

                                    <h3>C’è tanto da lavorare…</h3>
                                    <p>
                                        Approfondisci le tue competenze su React
                                        e lo stack MERN esplorando la
                                        documentazione ufficiale di ogni
                                        tecnologia e consultando tutorial
                                        online. Sperimenta con diverse
                                        funzionalità e tecniche per migliorare
                                        la tua comprensione dello stack e per
                                        creare applicazioni sempre più avanzate
                                        e sofisticate. E se ti trovi in
                                        difficoltà con il tuo progetto,
                                        contattaci pure per avere assistenza.
                                        Buon coding.
                                    </p>

                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
